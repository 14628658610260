import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from './component/Home.js';
import Navbar from './component/Navbar.js';
import Terms from './component/terms.jsx';
import Privacy from './component/privacy.jsx';

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <div className='custom-get-section'>
          <div>
            <div data-uk-navbar>
              <div className="uk-navbar-left">
                <ul className="uk-navbar-nav">
                  <p className='uk-text-center'>© 2024 Know your Karmaa. All rights reserved</p>
                </ul>
              </div>
              <div className="uk-navbar-right">
                <ul className="uk-navbar-nav">
                  <li>
                    <Link to="/terms" className='uk-text-capitalize text-white'>
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" className='uk-text-capitalize text-white'>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
